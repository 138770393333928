// ** Third Party Components
import styled from "styled-components";
// #####################################################

export const StyledPanelBody = styled.div`
	background-color: rgba(0, 0, 0, 0.3);
	padding: 1rem;
	border-radius: var(--dz-ref-border-radius-1-50);
	border-top-right-radius: 0;
	border-top-left-radius: 0;
	color: var(--dz-ref-color-white);
`;

// #####################################################
