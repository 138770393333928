// ** Third Party Components
import styled from "styled-components";

// ** Custom Components
import Button from "components/base/Button";

// #####################################################

export const StyledPanelNav = styled(Button)`
	display: block;
	flex: 1;
	padding: 1rem;
	border-radius: var(--dz-ref-border-radius-1-50);
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	font-size: 1.25rem;
	font-weight: bold;
	color: ${({ theme: { colors } }) => colors.whiteTrueAlways};
	&.active {
		background-color: rgba(0, 0, 0, 0.3);
	}

	&:not(.active) {
		background-color: rgba(0, 0, 0, 0.065);
	}

	&:first-child {
		border-top-right-radius: 0;
	}
	&:last-child {
		border-top-left-radius: 0;
	}

	text-overflow: ellipsis;
	overflow: hidden;

	&:not(.active):hover,
	&:not(.active):focus {
		background-color: rgba(0, 0, 0, 0.125);
	}

	text-align: center;
`;

// #####################################################
