import React from "react";

// ** Third Party Components
import styled, { css } from "styled-components";

// ** Custom Components
import Button from "components/base/Button";

// ** Custom Styles
import { resolveProps, marginable } from "styles/styledComponentsMixins";
import { breakpoints } from "styles/grid";

// #####################################################

const StyledButtonFloating = styled(({ ...props }) => (
	<Button {...resolveProps(props, marginable)} />
))(() => {
	return css`
		position: fixed;
		bottom: 0.75rem;
		left: 50%;
		transform: translateX(-50%);
		z-index: 999;
		cursor: pointer;
		white-space: normal;
		text-wrap: nowrap;
		text-align: center;
		font-size: 1.3rem;
		line-height: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1rem 0.5rem;
		margin: 0rem;
		width: calc(100% - 2rem);
		border-radius: 2.5rem;
		background-color: var(--dz-sys-button-landing-background-color);
		color: var(--dz-sys-button-landing-text-color);
		box-shadow: var(--dz-sys-button-shadow);

		${marginable.css}

		&:hover {
			background-color: var(
				--dz-sys-button-landing-background-color-hover
			);
		}

		&:active {
			background-color: var(
				--dz-sys-button-landing-background-color-active
			);
		}

		@media (min-width: ${breakpoints.tablet}px) {
			width: calc(${breakpoints.tablet - 1}px);
		}

		@media (min-width: ${breakpoints.desktop}px) {
			width: calc(${breakpoints.desktop - 1}px);
		}

		@media (min-width: ${breakpoints.giant}px) {
			width: calc(${breakpoints.giant - 1}px);
		}

		@media (min-width: ${breakpoints.veryGiant}px) {
			width: calc(${breakpoints.veryGiant - 1}px);
		}
	`;
});

// #####################################################

export const ButtonFloating = ({ label, children, ...rest }) => {
	const textContent = children || label;

	return <StyledButtonFloating {...rest}>{textContent}</StyledButtonFloating>;
};

// #####################################################
