// ** React Imports
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

// ** Redux & Store & Actions
import { connect, useSelector } from "react-redux";
import { login } from "store/modules/auth";

// ** Third Party Components
import { useTranslation } from "react-i18next";
import md5 from "md5";

// ** Login Components
import UsernameInput from "./components/UsernameInput";
import PasswordInput from "./components/PasswordInput";
import { ButtonLanding } from "components/layout/Button/ButtonLanding";
import PasswordRecoverButton from "./components/PasswordRecoverButton";

// ** Custom Hooks
import { useFingerprint } from "hooks/useFingerprint";
import { useGetUserAfterLogin } from "hooks/useGetUserAfterLogin";

// ** Utils & Helpers
import api from "helpers/ApiClient";

// ** Config
import configGlobal from "configuration";

// #####################################################

const Login = ({ login, language, setActiveTab }) => {
	const [loginLoading, setLoginLoading] = useState(false);
	const [username, setUsername] = useState("");
	const [usernameError, setUsernameError] = useState(false);
	const [password, setPassword] = useState("");
	const [passwordError, setPasswordError] = useState(false);

	const { getFingerprint } = useFingerprint();

	const { t } = useTranslation(["landing"]);

	const isClient = useSelector((state) => state.global.isClient);

	let firebaseHashObject = null;

	try {
		firebaseHashObject =
			isClient && localStorage
				? localStorage.getItem(
						configGlobal.firebase.localStorage.itemName
				  )
				: null;
	} catch (e) {
		firebaseHashObject = null;
	}

	const activateFcmToken = (firebaseHashObject) => {
		const firebaseHash = firebaseHashObject
			? JSON.parse(firebaseHashObject)?.token
			: null;

		return api.put(`/setting/fcm/activate`, {
			id: null,
			hash: firebaseHash ? md5(firebaseHash) : undefined,
		});
	};

	// ** Metoda pobiera dane użytkownia. W przypadku gdy aktualny język strony
	// ** różni się od języka w profilu użytkownika - jezyk strony ustawiany jest jak na profilu.
	const { getUserAfterLogin } = useGetUserAfterLogin();

	const handleSubmit = (e) => {
		e.preventDefault();

		if (username === "") {
			setUsernameError(true);
			return;
		}
		if (password === "") {
			setPasswordError(true);
			return;
		}

		if (username && password) {
			setLoginLoading(true);
			setUsernameError(false);
			setPasswordError(false);

			getFingerprint().then((fingerprintRes) => {
				login(username, password, fingerprintRes).then((res) => {
					setLoginLoading(false);
					if (res) {
						getUserAfterLogin();
						if (firebaseHashObject) {
							activateFcmToken(firebaseHashObject);
						}
					}
				});
				setPassword("");
			});
		}
	};

	const location = useLocation();

	// ** Efekt odpowiadający za aktywację zakładki odzyskiwania hasła
	// ** na podstawie danych przekaznych w 'location.state'
	useEffect(() => {
		const { activeLast } = location.state || {};

		if (activeLast === true) {
			setActiveTab("recover");
		}
	}, []);

	const loginButtonText = useMemo(() => {
		const text = t("landing:login.action");
		return text;
	}, [language]);

	// #####################################################

	return (
		<>
			<form onSubmit={handleSubmit} autoComplete="on">
				<UsernameInput
					username={username}
					setUsername={setUsername}
					usernameError={usernameError}
					setUsernameError={setUsernameError}
					setPasswordError={setPasswordError}
				/>
				<PasswordInput
					password={password}
					setPassword={setPassword}
					passwordError={passwordError}
					setPasswordError={setPasswordError}
					setUsernameError={setUsernameError}
				/>
				<ButtonLanding disabled={loginLoading} type="submit">
					{loginButtonText}
				</ButtonLanding>
			</form>
			<PasswordRecoverButton setActiveTab={setActiveTab} />
		</>
	);
};

// #####################################################

const mapStateToProps = ({ global: { language } }) => ({
	language,
});

export default connect(mapStateToProps, {
	login,
})(Login);

// #####################################################
