import styled from "styled-components";

export const RecoverButtonStyled = styled.button`
	color: var(--color-whiteTrueAlways-darken-200);
	border-radius: 0.75rem;
	padding: 0.25rem 0.75rem;
	background-color: rgba(0, 0, 0, 0.25);
	font-size: 0.9rem;
	box-shadow: ${({ theme }) =>
		theme.utils.createBoxShadow(["buttonWithText"])};

	&:hover,
	&:focus {
		background-color: rgba(0, 0, 0, 0.35);
		text-decoration: underline;
	}

	white-space: nowrap;
`;
